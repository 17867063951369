import { environment } from './../environments/environment';
import { LoadingService } from './dashboard/services/loading.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'marketplace-front';
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    public translate: TranslateService,
    public loading: LoadingService,
    private router: Router
  ) {
    this.favIcon.href = environment.customCssProperties.favicon;
    this.setAppLanguages();
    this.setApplicationInsights();
  }

  setAppLanguages() {
    // this.translate.setDefaultLang('en');
    // this.translate.use(this.translate.getBrowserLang());

    this.translate.addLangs(environment.appLanguages.map((x) => x.key));
    const initialLang =
      environment.appLanguages.find(
        (x) => x.key === this.translate.getBrowserLang()
      ) || environment.defaultLanguage;
    this.translate.setDefaultLang(initialLang.key);
    this.translate.use(initialLang.key);
  }

  setApplicationInsights() {
    const angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.insights.key,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    appInsights.loadAppInsights();
  }
}
